import store from "./index";

//const URL_API = process.env.NODE_ENV === 'development' ? 'http://localhost:4029' : 'https://qa.desk.onkimia.com.mx';
//const URL_API = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://desk.onkimia.com.mx';
//const URL_API = process.env.NODE_ENV === 'development' ? 'http://localhost:3003' : 'https://desk.clinicadolor.onkimia.com.mx';
const URL_API = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://desk.gastro.onkimia.com.mx';
//const URL_API = process.env.NODE_ENV === 'development' ? 'http://localhost:4029' : 'https://qa.desk.onkimia.com.mx';


export const requestFetch = async (path, method, data ) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "application/json");


    const requestOptions = {
        method: method,
        headers: myHeaders,
        body: JSON.stringify(data) || null,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.json();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        return {error};
    }
}

export const requestFetchFile = async (path, method, data ) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", store.getters["auth/getToken"]);
    myHeaders.append("Content-Type", "*");
    const requestOptions = {
        method: method,
        headers: myHeaders,
        redirect: 'follow'

    };

    try {
        const response = await fetch(`${URL_API}${path}`, requestOptions);
        const data = await response.blob();

        if (data.error) throw data.error;
        return data;
    } catch (error) {
        console.log(error);
        return {error};
    }
}

